<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Payments
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="payments"
              :search="search"
          >
            <template v-slot:top>
              <v-container fluid>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ formatCurrency(item.amount) }}
            </template>
            <template v-slot:item.transaction_date="{ item }">
              {{ formatDateTime(item.transaction_date) }}
            </template>
            <template v-slot:item.completed="{ item }">
              <span class="green--text" v-if="item.completed">Completed</span>
              <span class="orange--text" v-if="!item.completed">Pending</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import dateFormat from '../mixins/date'
import currencyFormat from '../mixins/currency'

export default {
  name: "Payments",
  mixins: [dateFormat, currencyFormat],
  data: () => ({
    search: '',
    headers: [
      {text: 'User', value: 'user.username'},
      {text: 'Package', value: 'package'},
      {text: 'Amount', value: 'amount'},
      {text: 'Date', value: 'transaction_date'},
      {text: 'Status', value: 'completed'}
    ],
    csvLabels: {
      username: {title: 'Username'}
    }
  }),
  computed: {
    ...mapState(['payments']),
  }
}
</script>

<style scoped>

</style>