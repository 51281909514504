export default {
    methods: {
        formatCurrency: (amount) => {
            if (!amount) {
                return '-'
            }
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' }).format(amount)
        }
    }
}
